import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IsEllipsisActiveDirective } from 'src/app/shared/directives/is-ellipsis-active.directive';
import { ProgressSpinnerComponent } from '../../../shared/component/progress-spinner/progress-spinner.component';
import { Patient } from '../../models/patient';

@Component({
  selector: 'app-patients-grid',
  imports: [IsEllipsisActiveDirective, MatIconModule, ProgressSpinnerComponent],
  templateUrl: './patients-grid.component.html',
  styleUrl: './patients-grid.component.scss',
})
export class PatientsGridComponent {
  @Input() set data(patients: Patient[]) {
    this.patients = patients;
  }
  @Input() isLoading: boolean | null = false;
  @Input() isDirty: boolean | null = false;
  items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);
  @Output() patientClickEvent = new EventEmitter<number>();
  patients: Patient[];
}
