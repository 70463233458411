<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav mode="side" opened>
    <div class="mt-15"></div>
    <div *ngFor="let navListItem of navList">
      <mat-list-item
        data-test="navListItem"
        class="pl-0"
        role="listitem"
        routerLink="{{ navListItem.route }}"
        [attr.data-test]="'nav-' + navListItem.route">
        <div
          class="nav-btn"
          routerLinkActive="router-link-active"
          [matTooltipDisabled]="!isCollapsed"
          [matTooltip]="navListItem.name"
          matTooltipPosition="after">
          <div [ngClass]="{ 'justify-center': isCollapsed, 'pl-10': !isCollapsed }">
            <mat-icon [fontIcon]="navListItem.icon" class="material-symbols-outlined"></mat-icon>
            <p *ngIf="!isCollapsed">{{ navListItem.name }}</p>
          </div>
        </div>
      </mat-list-item>
    </div>
    <div *ngIf="!isCollapsed" class="expanded-btn">
      <button mat-icon-button type="button" (click)="isCollapsed = !isCollapsed" data-test="btnCollaspeNav">
        <mat-icon fontIcon="keyboard_double_arrow_left" class="material-symbols-outlined"></mat-icon>
      </button>
    </div>
    <div *ngIf="isCollapsed" class="collapsed-btn">
      <button mat-icon-button type="button" (click)="isCollapsed = !isCollapsed" data-test="btnExpandNav">
        <mat-icon fontIcon="keyboard_double_arrow_right" class="material-symbols-outlined"></mat-icon>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
