import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { DataSort } from 'src/app/core/models/data-sort';
import { SortDirection } from '../../../core/enums/sort-direction';
import { formatDateWithoutTime } from '../../../shared/functions/date.functions';
import { patientsSort } from '../../data/patients-sort';
import { IPatientSearchFilters } from '../../models/patient-search-filters';
import { PatientSortType } from '../../models/patient-sort-type';

@Component({
  selector: 'app-patients-filters',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskDirective,
  ],
  providers: [provideNgxMask(), provideNativeDateAdapter()],
  templateUrl: './patients-filters.component.html',
  styleUrl: './patients-filters.component.scss',
})
export class PatientsFiltersComponent implements OnChanges {
  @Input() set searchFilters(filters: IPatientSearchFilters) {
    this.setSearchFilters(filters);
  }
  @Input() sortBy: DataSort;
  @Input() sortDirection: SortDirection;
  @Output() resetEvent = new EventEmitter<void>();
  @Output() searchEvent = new EventEmitter<IPatientSearchFilters>();
  @Output() sortEvent = new EventEmitter<PatientSortType>();
  sortTypes: PatientSortType[] = [
    {
      description: 'Name: A to Z',
      sortColumn: patientsSort[0].value,
      sortDirection: SortDirection.Ascending,
    },
    {
      description: 'Name: Z to A',
      sortColumn: patientsSort[0].value,
      sortDirection: SortDirection.Descending,
    },
    {
      description: 'DOB: Old to Recent',
      sortColumn: patientsSort[1].value,
      sortDirection: SortDirection.Descending,
    },
    {
      description: 'DOB: Recent to Old',
      sortColumn: patientsSort[1].value,
      sortDirection: SortDirection.Ascending,
    },
  ];
  defaultSortType: PatientSortType = this.sortTypes[0];

  patientSearchForm = new FormGroup({
    displayName: new FormControl<string | null>(null, Validators.minLength(3)),
    dateOfBirth: new FormControl<Date | null>(null),
    medicalRecordNumber: new FormControl<string | null>(null, Validators.minLength(3)),
    socialSecurityNumber: new FormControl<string | null>(null, Validators.minLength(3)),
    phoneNumber: new FormControl<string | null>(null, Validators.minLength(3)),
    sortBy: new FormControl<PatientSortType>(this.defaultSortType),
  });

  get patientSearchFilters(): IPatientSearchFilters {
    const getValue = (value: string) => (value && value.length >= 3 ? value : null);
    return {
      displayName: getValue(this.patientSearchForm.controls['displayName'].value),
      dateOfBirth: getValue(formatDateWithoutTime(this.patientSearchForm.controls['dateOfBirth'].value)),
      medicalRecordNumber: getValue(this.patientSearchForm.controls['medicalRecordNumber'].value),
      socialSecurityNumber: getValue(this.patientSearchForm.controls['socialSecurityNumber'].value),
      phoneNumber: getValue(this.patientSearchForm.controls['phoneNumber'].value),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sortBy'] || changes['sortDirection']) {
      this.setSort();
    }
  }

  setSearchFilters(searchFilters: IPatientSearchFilters): void {
    if (searchFilters) {
      this.patientSearchForm.controls['displayName'].patchValue(searchFilters.displayName);
      this.patientSearchForm.controls['dateOfBirth'].patchValue(
        searchFilters.dateOfBirth ? new Date(searchFilters.dateOfBirth) : null
      );
      this.patientSearchForm.controls['medicalRecordNumber'].patchValue(searchFilters.medicalRecordNumber);
      this.patientSearchForm.controls['socialSecurityNumber'].patchValue(searchFilters.socialSecurityNumber);
      this.patientSearchForm.controls['phoneNumber'].patchValue(searchFilters.phoneNumber);
    }
  }

  setSort(): void {
    let sort = this.defaultSortType;
    if (this.sortBy && this.sortDirection) {
      sort = this.sortTypes.find(
        sortType => sortType.sortColumn === this.sortBy.value && sortType.sortDirection === this.sortDirection
      );
    }

    this.patientSearchForm.controls['sortBy'].patchValue(sort);
  }

  onSortChange(sortType: PatientSortType): void {
    this.sortEvent.emit(sortType);
  }

  reset() {
    this.patientSearchForm.controls['sortBy'].patchValue(this.defaultSortType);
    this.resetEvent.emit();
  }

  search() {
    if (this.patientSearchForm.valid) {
      const activeSearchFilters = Object.keys(this.patientSearchFilters).filter(
        key =>
          this.patientSearchForm.get(key).value !== null &&
          (key === 'dateOfBirth' || this.patientSearchForm.get(key).value.length >= 3)
      );

      //at least 1 search filter must be applied to initiate search
      if (activeSearchFilters.length > 0) this.searchEvent.emit(this.patientSearchFilters);
    }
  }
}
