import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { IPatientContacts } from '../../models/patient-contacts';

@Component({
  selector: 'app-patient-contacts-ui',
  imports: [MatButtonModule, MatIconModule, NgxMaskPipe],
  providers: [provideNgxMask()],
  templateUrl: './patient-contacts-ui.component.html',
  styleUrl: './patient-contacts-ui.component.scss',
})
export class PatientContactsUiComponent {
  @Input() contacts: IPatientContacts | null = null;
  @Output() editEvent = new EventEmitter();
}
