import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of } from 'rxjs';
import { PatientsService } from '../../services/patients/patients.service';

import * as patientsActions from '../actions/patients.actions';
import * as patientsSelectors from '../selectors/patients.selectors';

@Injectable()
export class PatientsEffects {
  constructor(
    private actions$: Actions,
    private patientsService: PatientsService,
    private store: Store,
    private logger: NGXLogger
  ) {}

  getPatients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        patientsActions.getPatientsSearch,
        patientsActions.setPageNumber,
        patientsActions.setPageSize,
        patientsActions.nextPage
      ),
      concatLatestFrom(() => [
        this.store.select(patientsSelectors.selectSearchFilters),
        this.store.select(patientsSelectors.selectSortBy),
        this.store.select(patientsSelectors.selectSortDirection),
        this.store.select(patientsSelectors.selectPageNumber),
        this.store.select(patientsSelectors.selectPageSize),
      ]),
      mergeMap(([, searchFilters, sortColumn, sortDirection, pageNumber, pageSize]) => {
        return this.patientsService
          .postSearchPatients(searchFilters, sortColumn, sortDirection, pageNumber, pageSize)
          .pipe(
            map(response => {
              let isAtEndOfData = false;
              const totalRecordCount = response.totalRecordCount;
              let currentPage = pageNumber || 1;
              let patients = response.result;

              if (patients == null || patients.length == 0) {
                patients = [];
                currentPage--;
                isAtEndOfData = true;
              }

              return patientsActions.getPatientsSearchSuccess({
                patients,
                pageNumber: currentPage <= 0 ? 1 : currentPage,
                isAtEndOfData,
                totalRecordCount,
              });
            }),
            catchError(err => {
              this.logger.error(err);
              return of(patientsActions.getPatientsSearchFailure({ error: err }));
            })
          );
      })
    );
  });
}
