import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Store } from '@ngrx/store';
import { setTheme } from 'src/app/core/store/actions/app.actions';
import { selectTheme } from 'src/app/core/store/selector/app.selector';

@Component({
  selector: 'app-user-menu',
  imports: [CommonModule, FormsModule, MatIconModule, MatMenuModule, MatSlideToggleModule, ReactiveFormsModule],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {
  private store = inject(Store);

  theme$ = this.store.select(selectTheme);

  setTheme = (event: MatSlideToggleChange) =>
    this.store.dispatch(setTheme({ theme: event.checked ? 'dark' : 'light' }));
}
