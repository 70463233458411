import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PatientProfile } from '../../models/patient-profile';

@Component({
  selector: 'app-patient-information-ui',
  imports: [MatIconModule, MatButtonModule, NgxMaskPipe, DatePipe],
  providers: [provideNgxMask()],
  templateUrl: './patient-information-ui.component.html',
  styleUrl: './patient-information-ui.component.scss',
})
export class PatientInformationUiComponent {
  @Input() profile: PatientProfile | null = null;
  @Output() editEvent = new EventEmitter();
}
