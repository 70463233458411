import { DataSort } from '../../core/models/data-sort';

export const patientsSort: DataSort[] = [
  {
    id: 1,
    value: 'displayName',
    display: 'Name',
    isDefault: true,
  },
  {
    id: 2,
    value: 'dateOfBirth',
    display: 'Date of Birth',
    isDefault: false,
  },
];
