<mat-toolbar class="flex justify-between">
  <div class="flex align-center flex-2 gap-50">
    <div id="imgDiv" class="logo cursor" (click)="goHome()" (keyup)="goHome()" tabindex="0" data-test="btnHome">
      <img id="imgLogo" src="logo-small.svg" class="mr-5 color-white" alt="Logo image" />
    </div>
    <div class="location-sel">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>Location</mat-label>
        <mat-select [value]="selectedLocationNameId" (selectionChange)="selectLocationNameIdEvent.emit($event.value)">
          @for (location of locations; track location) {
            <mat-option [value]="location.locationId">{{ location.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="flex-1 flex justify-end">
    <app-user-menu></app-user-menu>
    <!-- <button
      mat-icon-button
      type="button"
      class="account-icon-button"
      [matMenuTriggerFor]="headerMenu"
      #menuTrigger="matMenuTrigger">
      <mat-icon fontIcon="account_circle" class="material-symbols-outlined"></mat-icon>
    </button>
    <mat-menu #headerMenu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item>
        <mat-icon fontIcon="logout" class="material-symbols-outlined"></mat-icon>
        <span class="fs-16 fw-400">Logout</span>
      </button>
    </mat-menu> -->
  </div>
</mat-toolbar>
