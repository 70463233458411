import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { SchedulingTipsDialogComponent } from '../../../shared/component/layout/scheduling-tips-dialog/scheduling-tips-dialog.component';
import { LocationsService } from '../../services/locations/locations.service';
import { SchedulingTipsService } from '../../services/scheduling-tips/scheduling-tips.service';
import {
  getLocations,
  getLocationsFailure,
  getLocationsSuccess,
  getSchedulingTips,
  getSchedulingTipsFailure,
  getSchedulingTipsSuccess,
  openSchedulingTips,
  setTheme,
} from '../actions/app.actions';
import { selectSelectedLocationNameId } from '../selector/app.selector';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private locationsService: LocationsService,
    private schedulingTipsService: SchedulingTipsService,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private store: Store
  ) {}

  getLocations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getLocations),
      mergeMap(() => {
        return this.locationsService.getLocations().pipe(
          map(locations => {
            return getLocationsSuccess({ locations });
          }),
          catchError(error => {
            this.logger.error(error);
            return of(getLocationsFailure({ error }));
          })
        );
      })
    );
  });

  openSchedulingTipsDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(openSchedulingTips),
        tap(action => {
          this.dialog.open(SchedulingTipsDialogComponent, {
            id: 'scheduling-tips-dialog',
            data: {
              locationName: action.locationName,
            },
            disableClose: false,
            autoFocus: false,
            maxWidth: '800px',
            minWidth: '400px',
            position: { top: '60px' },
          });
        })
      );
    },
    { dispatch: false }
  );

  getSchedulingTips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getSchedulingTips),
      concatLatestFrom(() => [this.store.select(selectSelectedLocationNameId)]),
      mergeMap(([, locationId]) => {
        return this.schedulingTipsService.getSchedulingTips(locationId).pipe(
          map(schedulingTips => {
            return getSchedulingTipsSuccess({ schedulingTips });
          }),
          catchError(error => {
            this.logger.error(error);
            return of(getSchedulingTipsFailure({ error }));
          })
        );
      })
    );
  });

  setTheme$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(setTheme),
        tap(action => {
          document.body.style.setProperty('color-scheme', action.theme);
        })
      );
    },
    { dispatch: false }
  );
}
