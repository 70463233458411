import { createAction, props } from '@ngrx/store';
import { DataSort } from '../../../core/models/data-sort';
import { Patient } from '../../models/patient';
import { PatientSearchFilters } from '../../models/patient-search-filters';

export const getPatientsSearch = createAction(
  '[Patients] Get Patients Search',
  props<{ searchFilters: PatientSearchFilters }>()
);

export const getPatientsSearchSuccess = createAction(
  '[Patients] Get Patients Search Success',
  props<{
    patients: Patient[];
    pageNumber: number;
    isAtEndOfData: boolean;
    totalRecordCount: number;
  }>()
);

export const getPatientsSearchFailure = createAction('[Patients] Get Patients Failure', props<{ error: any }>());

export const setPageNumber = createAction('[Patients] Set Page Number', props<{ pageNumber: number }>());

export const setPageSize = createAction('[Patients] Set Page Size', props<{ pageSize: number }>());

export const setSortBy = createAction('[Patients] Set Sort By', props<{ sortBy: DataSort }>());

export const setSortDirection = createAction('[Patients] Set Sort Direction', props<{ sortDirection: number }>());

export const resetFilters = createAction('[Patients] Reset Filters');

export const nextPage = createAction('[Patients] Next Page');
