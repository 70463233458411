<div class="user-icon" [matMenuTriggerFor]="menu" aria-label="User icon button with a menu" data-test="btnUserMenu">
  <p>JM</p>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item class="button-border">
    <mat-icon fontIcon="dark_mode" class="material-symbols-outlined"></mat-icon>
    <span>Dark Mode</span>
    <mat-slide-toggle
      [ngModel]="(theme$ | async) === 'dark'"
      (change)="setTheme($event)"
      class="ml-10"
      aria-label="Toggle dark mode"></mat-slide-toggle>
  </button>
  <button mat-menu-item>
    <mat-icon fontIcon="logout" class="material-symbols-outlined"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>
