import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { LocationName } from '../../../models/location-name';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'app-header',
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatToolbarModule,
    UserMenuComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  private router = inject(Router);

  @Input() locations: LocationName[] | null = null;
  @Input() selectedLocationNameId: number | null = -1;
  @Output() selectLocationNameIdEvent = new EventEmitter<number>();
  @Output() openSchedulingTipsEvent = new EventEmitter<string>();

  isSaving = false;

  title = environment.title;

  goHome() {
    this.router.navigate(['']);
  }
}
