import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Observable } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { DataSort } from '../../../core/models/data-sort';
import { patientsSort } from '../../data/patients-sort';
import { Patient } from '../../models/patient';
import { IPatientSearchFilters } from '../../models/patient-search-filters';
import { PatientSortType } from '../../models/patient-sort-type';
import {
  getPatientsSearch,
  nextPage,
  resetFilters,
  setSortBy,
  setSortDirection,
} from '../../store/actions/patients.actions';
import * as selectors from '../../store/selectors/patients.selectors';
import { PatientsFiltersComponent } from '../../ui/patients-filters/patients-filters.component';
import { PatientsGridComponent } from '../../ui/patients-grid/patients-grid.component';

@Component({
  selector: 'app-patients',
  imports: [CommonModule, InfiniteScrollDirective, PatientsFiltersComponent, PatientsGridComponent],
  templateUrl: './patients.component.html',
  styleUrl: './patients.component.scss',
})
export class PatientsComponent {
  patients$: Observable<Patient[]>;
  isLoading$: Observable<boolean>;
  searchFilters$: Observable<IPatientSearchFilters>;
  sortBy$: Observable<DataSort>;
  sortDirection$: Observable<SortDirection>;
  isDirty$: Observable<boolean>;
  isAtEndOfData$: Observable<boolean>;

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.patients$ = this.store.select(selectors.selectPatients);
    this.isLoading$ = this.store.select(selectors.selectIsLoading);
    this.searchFilters$ = this.store.select(selectors.selectSearchFilters);
    this.sortBy$ = this.store.select(selectors.selectSortBy);
    this.sortDirection$ = this.store.select(selectors.selectSortDirection);
    this.isDirty$ = this.store.select(selectors.selectIsDirty);
    this.isAtEndOfData$ = this.store.select(selectors.selectIsAtEndOfData);
  }

  resetFilters(): void {
    this.store.dispatch(resetFilters());
  }

  search(searchFilters: IPatientSearchFilters): void {
    this.store.dispatch(getPatientsSearch({ searchFilters }));
  }

  onSort(sort: PatientSortType): void {
    this.store.dispatch(setSortDirection({ sortDirection: sort.sortDirection }));

    let dataSort: DataSort | undefined = patientsSort.find(f => f.value === sort.sortColumn);
    if (dataSort === undefined) {
      dataSort = patientsSort.find(f => f.isDefault);
      this.store.dispatch(setSortBy({ sortBy: dataSort! }));
    } else {
      this.store.dispatch(setSortBy({ sortBy: dataSort }));
    }
  }

  patientClick() {
    this.router.navigate(['patient-profile']);
  }

  onScroll() {
    console.log('scroll');
    this.store.dispatch(nextPage());
  }
}
