import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AdministrativeScan } from '../../models/administrative-scan';

@Component({
  selector: 'app-administrative-scan-ui',
  imports: [MatButtonModule, DatePipe, MatIconModule],
  providers: [],
  templateUrl: './administrative-scan-ui.component.html',
  styleUrl: './administrative-scan-ui.component.scss',
})
export class AdministrativeScanUiComponent {
  @Input() administrativeScans: AdministrativeScan[] = [];
}
