import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProgressSpinnerComponent } from '../../../../../shared/component/progress-spinner/progress-spinner.component';
import { Appointment } from '../../models/appointment';
import { getAppointments } from '../../store/actions/appointment.actions';
import { selectAppointments } from '../../store/selectors/appointent.selectors';
import { AppointmentUiComponent } from '../../ui/appointment-ui/appointment-ui.component';

@Component({
  selector: 'app-appointment',
  imports: [ProgressSpinnerComponent, AppointmentUiComponent, CommonModule, LetDirective],
  templateUrl: './appointment.component.html',
  styleUrl: './appointment.component.scss',
})
export class AppointmentComponent implements OnInit {
  appointments$: Observable<Appointment[]>;

  constructor(private store: Store) {
    this.appointments$ = this.store.select(selectAppointments);
  }

  ngOnInit(): void {
    this.store.dispatch(getAppointments());
  }
}
