import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLocations, selectLocationNameId } from '../../../../core/store/actions/app.actions';
import { selectLocations, selectSelectedLocationNameId } from '../../../../core/store/selector/app.selector';
import { HeaderComponent } from '../header/header.component';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-layout-with-nav',
  imports: [CommonModule, HeaderComponent, SideNavComponent],
  templateUrl: './layout-with-nav.component.html',
  styleUrls: ['./layout-with-nav.component.scss'],
})
export class LayoutWithNavComponent implements OnInit {
  private store = inject(Store);

  locations$ = this.store.select(selectLocations);
  selectedLocationNameId$ = this.store.select(selectSelectedLocationNameId);

  ngOnInit(): void {
    this.store.dispatch(getLocations());
  }

  onSelectLocationNameId(locationNameId: number) {
    this.store.dispatch(selectLocationNameId({ locationNameId }));
  }
}
