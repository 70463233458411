import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SortDirection } from '../../../core/enums/sort-direction';
import { DataResponse } from '../../../core/models/data-response';
import { DataSort } from '../../../core/models/data-sort';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { Patient } from '../../models/patient';
import { IPatientSearchFilters } from '../../models/patient-search-filters';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  constructor(private utilityService: UtilityService) {}

  patientsEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/Patients';

  postSearchPatients(
    searchFilters: IPatientSearchFilters,
    sortBy: DataSort,
    sortDirection: SortDirection,
    pageNumber: number | undefined,
    pageSize: number | undefined
  ): Observable<DataResponse<Patient[]>> {
    const url = this.patientsEndpoint + '/Search';
    const searchParams = {
      pageNumber,
      pageSize,
      displayName: searchFilters.displayName,
      dateOfBirth: searchFilters.dateOfBirth,
      medicalRecordNumber: searchFilters.medicalRecordNumber,
      socialSecurityNumber: searchFilters.socialSecurityNumber,
      phoneNumber: searchFilters.phoneNumber,
      sortColumn: sortBy.value,
      sortDirection: sortDirection,
    };
    return this.utilityService.postRequest<DataResponse<Patient[]>>(url, searchParams, false, false, true);
  }
}
