@if (patients && patients.length > 0) {
  <div id="patientList">
    @for (patient of patients; track patient) {
      <div id="patientRow" class="flex justify-start gap-12 fs-14 align-center">
        <mat-icon fontIcon="keyboard_arrow_right" class="material-symbols-outlined fs-32"></mat-icon>
        <div id="patient-info" class="patient-row-col">
          <div
            class="flex align-center gap-8 cursor"
            (click)="patientClickEvent.emit(patient.patientId)"
            (keyup)="patientClickEvent.emit(patient.patientId)"
            tabindex="0">
            <mat-icon fontIcon="account_circle" class="material-symbols-outlined"></mat-icon>
            <div>
              <div appIsEllipsisActive class="patient-name">{{ patient.displayName }}</div>
              <div class="patient-info">{{ patient.ageBirthDateDescription }}</div>
            </div>
          </div>
        </div>
        <div class="patient-row-col">
          <div class="patient-row-col-item">
            <mat-icon fontIcon="call" class="material-symbols-outlined"></mat-icon>
            <p>{{ patient.homePhoneNumber }}</p>
          </div>
          <div class="patient-row-col-item">
            <mat-icon fontIcon="mail" class="material-symbols-outlined"></mat-icon>
            <p appIsEllipsisActive class="patient-address">{{ patient.address }}</p>
          </div>
        </div>
        <div class="patient-row-col">
          <div class="patient-row-col-item">
            <mat-icon fontIcon="account_box" class="material-symbols-outlined"></mat-icon>
            <div>{{ patient.medicalRecordNumber }}</div>
          </div>
          <div class="patient-row-col-item">
            <mat-icon fontIcon="stethoscope" class="material-symbols-outlined"></mat-icon>
            <div>{{ patient.primaryCareProviderFullName }}</div>
          </div>
        </div>
        <div class="patient-row-col">
          <div class="patient-row-col-item">
            <mat-icon fontIcon="full_coverage" class="material-symbols-outlined"></mat-icon>
            <div>{{ patient.payerName }}</div>
          </div>
          <div class="patient-row-col-item">
            <mat-icon fontIcon="alternate_email" class="material-symbols-outlined"></mat-icon>
            <div>{{ patient.emailAddress }}</div>
          </div>
        </div>
      </div>
    }
  </div>
} @else if (isLoading) {
  <div class="mt-20">
    <app-progress-spinner></app-progress-spinner>
  </div>
} @else if (isDirty && !isLoading) {
  <p id="noResultsMsg" class="no-results">No data found for filters</p>
} @else {
  <p id="searchMsg" class="no-results">Please search for a patient using the Filter and Sort tool on the left</p>
}
