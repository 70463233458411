import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AdministrativeScanComponent } from '../../administrative-scan/feature/administrative-scan/administrative-scan.component';
import { AppointmentComponent } from '../../appointment/feature/appointment/appointment.component';
import { PatientContactsComponent } from '../../patient-contacts/feature/patient-contacts/patient-contacts.component';
import { PatientDemographicsComponent } from '../../patient-demographics/feature/patient-demographics/patient-demographics.component';
import { PatientEmploymentComponent } from '../../patient-employment/feature/patient-employment/patient-employment.component';
import { PatientInformationComponent } from '../../patient-information/feature/patient-information/patient-information.component';
@Component({
  selector: 'app-patient-profile',
  imports: [
    CommonModule,
    PatientDemographicsComponent,
    PatientContactsComponent,
    PatientEmploymentComponent,
    PatientInformationComponent,
    AppointmentComponent,
    AdministrativeScanComponent,
  ],
  templateUrl: './patient-profile.component.html',
  styleUrl: './patient-profile.component.scss',
})
export class PatientProfileComponent {}
