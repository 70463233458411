/* eslint-disable @ngrx/prefix-selectors-with-select */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPatients from '../reducer/patients.reducer';

export const patientsFeatureSelector = createFeatureSelector<fromPatients.PatientsState>(
  fromPatients.patientsFeatureKey
);

const { selectEntities, selectAll } = fromPatients.patientsAdapter.getSelectors();

export const selectPatientEntities = createSelector(patientsFeatureSelector, selectEntities);

export const selectPatients = createSelector(patientsFeatureSelector, selectAll);

export const selectError = createSelector(patientsFeatureSelector, state => state.baseState.error);

export const selectSortDirection = createSelector(patientsFeatureSelector, state => state.baseState.sortDirection);

export const selectSortBy = createSelector(patientsFeatureSelector, state => state.baseState.sortBy);

export const selectIsLoading = createSelector(patientsFeatureSelector, state => state.baseState.isLoading);

export const selectPageNumber = createSelector(patientsFeatureSelector, state => state.baseState.pageNumber);

export const selectIsAtEndOfData = createSelector(patientsFeatureSelector, state => state.baseState.isAtEndOfData);

export const selectPageSize = createSelector(patientsFeatureSelector, state => state.baseState.pageSize);

export const selectSearchFilters = createSelector(patientsFeatureSelector, state => state.searchFilters);

export const selectIsDirty = createSelector(patientsFeatureSelector, state => state.isDirty);

export const selectTotalRecordCount = createSelector(
  patientsFeatureSelector,
  state => state.baseState.totalRecordCount
);
