import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { Component, DoCheck, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayService } from '../../../core/services/overlay/overlay.service';

@Component({
  selector: 'app-progress-spinner-overlay',
  imports: [MatProgressSpinnerModule],
  templateUrl: './progress-spinner-overlay.component.html',
  styleUrls: ['./progress-spinner-overlay.component.scss'],
})
export class ProgressSpinnerOverlayComponent implements OnInit, DoCheck {
  @Input() displayProgressSpinner: boolean | null = false;

  @ViewChild('progressSpinnerRef')
  public progressSpinnerRef: TemplateRef<unknown> | undefined;
  public progressSpinnerOverlayConfig: OverlayConfig | undefined;
  public overlayRef: OverlayRef | undefined;

  constructor(
    private vcRef: ViewContainerRef,
    private overlayService: OverlayService
  ) {}

  ngOnInit() {
    // Config for Overlay Service
    this.progressSpinnerOverlayConfig = {
      hasBackdrop: true,
      positionStrategy: this.overlayService.positionGloballyCenter(),
    };

    // Create Overlay for progress spinner
    this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);
  }
  ngDoCheck() {
    if (this.overlayRef !== undefined && this.progressSpinnerRef !== undefined) {
      // Based on status of displayProgressSpinner attach/detach overlay to progress spinner template
      if (this.displayProgressSpinner && !this.overlayRef.hasAttached()) {
        this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
      } else if (!this.displayProgressSpinner && this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
      }
    }
  }
}
