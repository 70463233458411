import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { PatientProfileComponent } from './patient/patient-profile/feature/patient-profile/patient-profile.component';
import { LayoutWithNavComponent } from './shared/component/layout/layout-with-nav/layout-with-nav.component';
import { PatientsComponent } from './patients/feature/patients/patients.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutWithNavComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [authGuard],
      },
      {
        path: 'patients',
        component: PatientsComponent,
        canActivate: [authGuard],
      },
      {
        path: 'patient-profile',
        component: PatientProfileComponent,
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
