import { createAction, props } from '@ngrx/store';
import { LocationName } from '../../../shared/models/location-name';
import { SchedulingTips } from '../../models/scheduling-tips';

export const getLocations = createAction('[App] Get Locations');

export const getLocationsSuccess = createAction(
  '[App] Get Locations Successful',
  props<{ locations: LocationName[] }>()
);

export const getLocationsFailure = createAction('[App] Get Locations Failure', props<{ error: any }>());

export const selectLocationNameId = createAction('[App] Select Location Name Id', props<{ locationNameId: number }>());

export const openSchedulingTips = createAction('[App] Open Scheduling Tips', props<{ locationName: string }>());

export const getSchedulingTips = createAction('[App] Get Scheduling Tips');

export const getSchedulingTipsSuccess = createAction(
  '[App] Get Scheduling Tips Success',
  props<{ schedulingTips: SchedulingTips }>()
);

export const getSchedulingTipsFailure = createAction('[App] Get Scheduling Tips Failure', props<{ error: any }>());

export const setTheme = createAction('[App] Set Theme', props<{ theme: 'dark' | 'light' }>());
