import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { setTheme } from './core/store/actions/app.actions';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  private store = inject(Store);

  private mediaQuery!: MediaQueryList;

  ngOnInit() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.store.dispatch(setTheme({ theme: this.mediaQuery.matches ? 'dark' : 'light' }));
    this.mediaQuery.addEventListener('change', this.themeChangeListener);
  }

  ngOnDestroy() {
    if (this.mediaQuery !== undefined) this.mediaQuery.removeEventListener('change', this.themeChangeListener);
  }

  private themeChangeListener = () => {
    this.store.dispatch(setTheme({ theme: this.mediaQuery.matches ? 'dark' : 'light' }));
  };
}
