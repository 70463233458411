<div class="page-header">
  <h2>Patient Search Results</h2>
</div>
<div class="search-container">
  <div class="filters-container">
    <app-patients-filters
      [searchFilters]="searchFilters$ | async"
      [sortBy]="sortBy$ | async"
      [sortDirection]="sortDirection$ | async"
      (searchEvent)="search($event)"
      (sortEvent)="onSort($event)"
      (resetEvent)="resetFilters()"></app-patients-filters>
  </div>
  <div
    class="list-container"
    infinite-scroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="1000"
    (scrolled)="onScroll()">
    <app-patients-grid
      class="w-100p"
      [data]="patients$ | async"
      [isDirty]="isDirty$ | async"
      [isLoading]="isLoading$ | async"
      (patientClickEvent)="patientClick()"></app-patients-grid>
  </div>
</div>
