import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { Observable } from 'rxjs';
import { ProgressSpinnerOverlayComponent } from '../../../../../shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from '../../../../../shared/component/progress-spinner/progress-spinner.component';
import { PatientEmployment } from '../../models/patient-employment';
import { EmploymentStatusType } from '../../models/types/employment-status-type';
import {
  closeEditDialog,
  getEmploymentStatusTypes,
  savePatientEmployment,
} from '../../store/actions/patient-employment.actions';
import { selectEmploymentStatuses, selectIsSaving } from '../../store/selectors/patient-employment.selectors';

@Component({
  selector: 'app-edit-employer',
  imports: [
    CommonModule,
    FormsModule,
    LetDirective,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    NgxMaskDirective,
    ProgressSpinnerComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule,
  ],
  providers: [provideNgxMask()],
  templateUrl: './edit-patient-employment.component.html',
  styleUrl: './edit-patient-employment.component.scss',
})
export class EditPatientEmploymentComponent implements OnInit {
  employmentStatusTypes$: Observable<EmploymentStatusType[] | null>;
  isSaving$: Observable<boolean | null>;

  patientEmployment: PatientEmployment | null;

  employmentForm = new FormGroup({
    employmentStatus: new FormControl<number | null>(null, Validators.required),
    employerName: new FormControl<string | null>(null, Validators.maxLength(100)),
    employerPhone: new FormControl<string>(''),
    workPhone: new FormControl<string | null>(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientEmployment: PatientEmployment | null;
      token: string | undefined;
    },
    private store: Store
  ) {
    this.employmentStatusTypes$ = this.store.select(selectEmploymentStatuses);
    this.isSaving$ = this.store.select(selectIsSaving);
    this.patientEmployment = data.patientEmployment;

    if (this.patientEmployment !== null) {
      this.employmentForm.controls['employmentStatus'].patchValue(this.patientEmployment.employmentStatusTypeId);
      this.employmentForm.controls['employerName'].patchValue(this.patientEmployment.employerName);
      this.employmentForm.controls['employerPhone'].patchValue(this.patientEmployment.employerPhoneNumber);
      this.employmentForm.controls['workPhone'].patchValue(this.patientEmployment.workPhoneNumber);
    }
  }

  ngOnInit(): void {
    this.store.dispatch(getEmploymentStatusTypes());
  }

  save() {
    if (this.employmentForm.valid && this.patientEmployment !== null) {
      const newEmployment = structuredClone(this.patientEmployment);
      newEmployment.employerName = this.employmentForm.controls['employerName'].value!;
      newEmployment.employmentStatus = null;
      newEmployment.employmentStatusTypeId = this.employmentForm.controls['employmentStatus'].value!;
      newEmployment.employerPhoneNumber =
        this.employmentForm.controls['employerPhone'].value === null
          ? null
          : this.employmentForm.controls['employerPhone'].value;
      newEmployment.workPhoneNumber =
        this.employmentForm.controls['workPhone'].value === null
          ? null
          : this.employmentForm.controls['workPhone'].value;

      this.store.dispatch(savePatientEmployment({ employment: newEmployment }));
    }
  }

  cancel() {
    this.store.dispatch(closeEditDialog());
  }
}
