import { ApplicationConfig, importProvidersFrom, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { routes } from './app.routes';
import { AppEffects } from './core/store/effects/app.effects';
import { appReducer } from './core/store/reducer/app.reducer';
import { AdministrativeScanEffects } from './patient/patient-profile/administrative-scan/store/effects/administrative-scan.effects';
import { administrativeScanReducer } from './patient/patient-profile/administrative-scan/store/reducer/administrative-scan.reducer';
import { AppointmentEffects } from './patient/patient-profile/appointment/store/effects/appointment.effects';
import { appointmentReducer } from './patient/patient-profile/appointment/store/reducer/appointment.reducer';
import { PatientContactsEffects } from './patient/patient-profile/patient-contacts/store/effects/patient-contacts.effects';
import { patientContactsReducer } from './patient/patient-profile/patient-contacts/store/reducer/patient-contacts.reducer';
import { PatientDemographicsEffects } from './patient/patient-profile/patient-demographics/store/effects/patient-demographics.effects';
import { patientDemographicsReducer } from './patient/patient-profile/patient-demographics/store/reducer/patient-demographics.reducer';
import { PatientEmploymentEffects } from './patient/patient-profile/patient-employment/store/effects/patient-employment.effects';
import { patientEmploymentReducer } from './patient/patient-profile/patient-employment/store/reducer/patient-employment.reducer';
import { PatientInformationEffects } from './patient/patient-profile/patient-information/store/effects/patient-information.effects';
import { patientInformationReducer } from './patient/patient-profile/patient-information/store/reducer/patient-information.reducer';
import { PatientsEffects } from './patients/store/effects/patients.effects';
import { patientsReducer } from './patients/store/reducer/patients.reducer';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({
      eventCoalescing: true,
    }),
    provideHttpClient(),
    provideRouter(routes),
    importProvidersFrom(LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG })),
    provideAnimationsAsync(),
    provideStore(),
    provideState({ name: 'app', reducer: appReducer }),
    provideState({ name: 'administrativeScan', reducer: administrativeScanReducer }),
    provideState({ name: 'appointment', reducer: appointmentReducer }),
    provideState({ name: 'patientInformation', reducer: patientInformationReducer }),
    provideState({ name: 'patientContacts', reducer: patientContactsReducer }),
    provideState({ name: 'patientDemographics', reducer: patientDemographicsReducer }),
    provideState({ name: 'patientEmployment', reducer: patientEmploymentReducer }),
    provideState({ name: 'patients', reducer: patientsReducer }),
    provideEffects([
      AppEffects,
      AdministrativeScanEffects,
      AppointmentEffects,
      PatientContactsEffects,
      PatientDemographicsEffects,
      PatientInformationEffects,
      PatientEmploymentEffects,
      PatientsEffects,
    ]),
    provideRouterStore(),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true, // If set to true, the connection is established within the Angular zone
    }),
    { provide: Window, useValue: window },
  ],
};
