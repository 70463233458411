import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProgressSpinnerComponent } from '../../../../../shared/component/progress-spinner/progress-spinner.component';
import { AdministrativeScan } from '../../models/administrative-scan';
import { getAdministrativeScans } from '../../store/actions/administrative-scan.actions';
import { selectAdministrativeScans } from '../../store/selectors/administrative-scan.selectors';
import { AdministrativeScanUiComponent } from '../../ui/administrative-scan-ui/administrative-scan-ui.component';

@Component({
  selector: 'app-administrative-scan',
  imports: [ProgressSpinnerComponent, AdministrativeScanUiComponent, CommonModule, LetDirective],
  templateUrl: './administrative-scan.component.html',
  styleUrl: './administrative-scan.component.scss',
})
export class AdministrativeScanComponent implements OnInit {
  administrativeScans$: Observable<AdministrativeScan[]>;

  constructor(private store: Store) {
    this.administrativeScans$ = this.store.select(selectAdministrativeScans);
  }

  ngOnInit(): void {
    this.store.dispatch(getAdministrativeScans());
  }
}
