import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProgressSpinnerComponent } from '../../../../../shared/component/progress-spinner/progress-spinner.component';
import { PatientProfile } from '../../models/patient-profile';
import {
  getPatientInformation,
  openEditPatientInformationDialog,
} from '../../store/actions/patient-information.actions';
import * as patientSelectors from '../../store/selectors/patient-information.selectors';
import { PatientInformationUiComponent } from '../../ui/patient-information-ui/patient-information-ui.component';

@Component({
  selector: 'app-patient-information',
  imports: [CommonModule, LetDirective, PatientInformationUiComponent, ProgressSpinnerComponent],
  templateUrl: './patient-information.component.html',
  styleUrl: './patient-information.component.scss',
})
export class PatientInformationComponent implements OnInit {
  profile$: Observable<PatientProfile | null>;

  constructor(private store: Store) {
    this.profile$ = this.store.select(patientSelectors.selectProfile);
  }

  ngOnInit(): void {
    this.store.dispatch(getPatientInformation());
  }

  editProfile() {
    this.store.dispatch(openEditPatientInformationDialog());
  }
}
