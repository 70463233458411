import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as baseState from '../../../core/models/base-state';
import { BaseState } from '../../../core/models/base-state';
import { patientsSort } from '../../data/patients-sort';
import { Patient } from '../../models/patient';
import { PatientSearchFilters } from '../../models/patient-search-filters';
import * as patientActions from '../actions/patients.actions';

export interface PatientsState extends EntityState<Patient> {
  baseState: baseState.BaseState;
  patients: Patient[];
  searchFilters: PatientSearchFilters;
  isDirty: boolean;
}

export const patientsAdapter = createEntityAdapter<Patient>({
  selectId: patient => patient.patientId,
});

export const initialState = patientsAdapter.getInitialState({
  baseState: new BaseState(patientsSort[0]),
  patients: [],
  searchFilters: new PatientSearchFilters(),
  isDirty: false,
});

export const patientsFeatureKey = 'patients';

export const patientsReducer = createReducer(
  initialState,
  on(patientActions.getPatientsSearch, (state, { searchFilters }) => {
    return {
      ...state,
      searchFilters,
      isDirty: true,
      baseState: baseState.setGet(state.baseState, true),
    };
  }),

  on(patientActions.setSortDirection, (state, { sortDirection }) => {
    const base = baseState.setSortDirection(state.baseState, sortDirection);
    base.isLoading = false;
    return {
      ...state,
      baseState: base,
    };
  }),

  on(patientActions.setSortBy, (state, { sortBy }) => {
    const base = baseState.setSortBy(state.baseState, sortBy);
    base.isLoading = false;
    return {
      ...state,
      baseState: base,
    };
  }),

  on(patientActions.resetFilters, state => {
    return {
      ...state,
      searchFilters: new PatientSearchFilters(),
      isDirty: false,
      baseState: baseState.resetFilters(state.baseState, patientsSort[0]),
    };
  }),

  on(patientActions.getPatientsSearchSuccess, (state, { patients }) => patientsAdapter.addMany(patients, state)),

  on(patientActions.getPatientsSearchSuccess, (state, { pageNumber, isAtEndOfData, totalRecordCount }) => {
    return {
      ...state,
      baseState: baseState.setGetSuccess(state.baseState, pageNumber, isAtEndOfData, totalRecordCount),
    };
  }),

  on(patientActions.getPatientsSearchFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error),
    };
  }),

  on(
    patientActions.getPatientsSearch,
    patientActions.setPageSize,
    patientActions.setPageNumber,
    patientActions.resetFilters,
    state => patientsAdapter.removeAll(state)
  ),

  on(patientActions.setPageNumber, (state, { pageNumber }) => {
    return {
      ...state,
      baseState: baseState.setPageNumber(state.baseState, pageNumber),
    };
  }),

  on(patientActions.setPageSize, (state, { pageSize }) => {
    return {
      ...state,
      baseState: baseState.setPageSize(state.baseState, pageSize),
    };
  }),

  on(patientActions.nextPage, state => {
    const currentPage = state.baseState.pageNumber + 1;
    return {
      ...state,
      baseState: baseState.setPageNumber(state.baseState, currentPage),
    };
  }),

  on(patientActions.nextPage, state => {
    return {
      ...state,
      baseState: baseState.setIsLoading(state.baseState, true),
    };
  })
);
