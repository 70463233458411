export interface IPatientSearchFilters {
  displayName: string | null;
  dateOfBirth: string | null;
  medicalRecordNumber: string | null;
  socialSecurityNumber: string | null;
  phoneNumber: string | null;
}

export class PatientSearchFilters implements IPatientSearchFilters {
  displayName: string | null;
  dateOfBirth: string | null;
  medicalRecordNumber: string | null;
  socialSecurityNumber: string | null;
  phoneNumber: string | null;

  constructor(
    displayName?: string | null,
    dateOfBirth?: string | null,
    medicalRecordNumber?: string | null,
    socialSecurityNumber?: string | null,
    phoneNumber?: string | null
  ) {
    this.displayName = displayName || null;
    this.dateOfBirth = dateOfBirth || null;
    this.medicalRecordNumber = medicalRecordNumber || null;
    this.socialSecurityNumber = socialSecurityNumber || null;
    this.phoneNumber = phoneNumber || null;
  }
}
