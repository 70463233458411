<!-- <div class="flex gap-40 justify-center h-100p filters-container">
  <div class="flex flex-col w-100p gap-20">
    <div class="title-container">
      <p class="flex justify-center fw-500 m-10">Filter and Sort</p>
    </div>
    <form [formGroup]="patientSearchForm" class="flex flex-col gap-20 m-15">
      <mat-form-field class="flex-2" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="displayName" data-test="iptName" />
        @if (patientSearchForm.controls['displayName'].hasError('minlength')) {
          <mat-error id="errNameMin" data-test="matErrNameMin">Must be at least 3 characters</mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="flex">
        <mat-label>Date of Birth</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          id="dateOfBirth"
          formControlName="dateOfBirth"
          data-test="dteDateOfBirth" />
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="flex-2" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
        <mat-label>Medical Record #</mat-label>
        <input matInput type="text" formControlName="medicalRecordNumber" data-test="iptMedicalRecordNumber" />
        @if (patientSearchForm.controls['medicalRecordNumber'].hasError('minlength')) {
          <mat-error id="errMrnMin" data-test="matErrMrnMin">Must be at least 3 characters</mat-error>
        }
      </mat-form-field>

      <mat-form-field class="flex-2" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
        <mat-label>Social Security #</mat-label>
        <input
          matInput
          type="text"
          formControlName="socialSecurityNumber"
          data-test="iptSocialSecurityNumber"
          mask="000-00-0000" />
        @if (patientSearchForm.controls['socialSecurityNumber'].hasError('mask')) {
          <mat-error id="errSsnLength" data-test="matErrSsnLength">Must be 9 digits</mat-error>
        }
      </mat-form-field>

      <mat-form-field class="flex-2" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
        <mat-label>Phone #</mat-label>
        <input matInput type="text" formControlName="phoneNumber" data-test="iptPhoneNumber" mask="(000) 000-0000" />
        @if (patientSearchForm.controls['phoneNumber'].hasError('mask')) {
          <mat-error id="errPhoneLength" data-test="matErrPhoneLength">Must be 10 digits</mat-error>
        }
      </mat-form-field>

      <mat-form-field id="sortBy" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>Sort By</mat-label>
        <mat-select
          formControlName="sortBy"
          (valueChange)="onSortChange($event)"
          class="type-select"
          data-test="selSortType">
          @for (sortType of sortTypes; track sortType) {
            <mat-option [value]="sortType">
              {{ sortType.description }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </form>

    <div class="flex justify-end gap-10 mb-20 mr-15 mt-auto">
      <div>
        <button id="btnReset" mat-stroked-button type="button" class="error-outline-btn" (click)="reset()">
          Reset
        </button>
      </div>

      <div>
        <button id="btnSearch" class="primary-solid-btn" mat-flat-button (click)="search()">Search</button>
      </div>
    </div>
  </div>
</div> -->

<div class="filter-container">
  <div class="title-container">
    <p class="flex justify-center fw-500">Filter and Sort</p>
  </div>
  <form [formGroup]="patientSearchForm" class="flex flex-col gap-20 m-15">
    <mat-form-field class="flex-2" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="displayName" data-test="iptName" />
      @if (patientSearchForm.controls['displayName'].hasError('minlength')) {
        <mat-error id="errNameMin" data-test="matErrNameMin">Must be at least 3 characters</mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="flex">
      <mat-label>Date of Birth</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        id="dateOfBirth"
        formControlName="dateOfBirth"
        data-test="dteDateOfBirth" />
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="flex-2" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
      <mat-label>Medical Record #</mat-label>
      <input matInput type="text" formControlName="medicalRecordNumber" data-test="iptMedicalRecordNumber" />
      @if (patientSearchForm.controls['medicalRecordNumber'].hasError('minlength')) {
        <mat-error id="errMrnMin" data-test="matErrMrnMin">Must be at least 3 characters</mat-error>
      }
    </mat-form-field>

    <mat-form-field class="flex-2" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
      <mat-label>Social Security #</mat-label>
      <input
        matInput
        type="text"
        formControlName="socialSecurityNumber"
        data-test="iptSocialSecurityNumber"
        mask="000-00-0000" />
      @if (patientSearchForm.controls['socialSecurityNumber'].hasError('mask')) {
        <mat-error id="errSsnLength" data-test="matErrSsnLength">Must be 9 digits</mat-error>
      }
    </mat-form-field>

    <mat-form-field class="flex-2" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
      <mat-label>Phone #</mat-label>
      <input matInput type="text" formControlName="phoneNumber" data-test="iptPhoneNumber" mask="(000) 000-0000" />
      @if (patientSearchForm.controls['phoneNumber'].hasError('mask')) {
        <mat-error id="errPhoneLength" data-test="matErrPhoneLength">Must be 10 digits</mat-error>
      }
    </mat-form-field>

    <mat-form-field id="sortBy" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
      <mat-label>Sort By</mat-label>
      <mat-select
        formControlName="sortBy"
        (valueChange)="onSortChange($event)"
        class="type-select"
        data-test="selSortType">
        @for (sortType of sortTypes; track sortType) {
          <mat-option [value]="sortType">
            {{ sortType.description }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>

  <div class="flex justify-end gap-10 mb-20 mr-15">
    <div>
      <button id="btnReset" mat-stroked-button type="button" class="error-outline-btn" (click)="reset()">Reset</button>
    </div>

    <div>
      <button id="btnSearch" class="primary-solid-btn" mat-flat-button (click)="search()">Search</button>
    </div>
  </div>
</div>
